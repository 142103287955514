.default {
    color: #bfbfbf;
}

.overidden {
    color: #696969;

    .type {
        font-weight: 600;
    }
}


.name {
    display: flex;
    align-items: center;
    gap: 8px;

    .nameText {
        display: inline-block;
        padding: 0px 4px;

        border: solid thin #d1d1d1;
        border-radius: 3px;
        background-color: #f5f5f5;

        font-family: monospace;

        max-width: 360px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .configFlags {
        display: flex;
        align-items: center;
        gap: 6px;

        margin-left: auto; // flags go to the right, next to the value
    }
}


.value {
    display: flex;
    gap: 6px;

    white-space: pre-line;
}

.type {
    color: #cb398f;
    font-family: monospace;
}

.sourceHeader {
    display: flex;
    gap: 6px;
}

.source {}


.nested {
    :global(.ant-table) {
        background-color: transparent;
    }
}

.configEntryTable {

    // popovers are ugly when perfectly rectangular
    :global(.ant-popover-content, .ant-popover-inner) {
        border-radius: 6px;

    }

    :global(.ant-popover-inner-content) {
        padding: 6px 12px;
        font-family: monospace;
    }

    // antd default margin makes no sense
    :global(.ant-table-cell button.ant-table-row-expand-icon) {
        margin-left: 0;
    }

    td {
        // no wrapping by default
        white-space: nowrap;
    }

    :global(.ant-table-row-level-1) {
        background: #f5f5f5;

        td {
            border-bottom-color: #e7e7e7;
            white-space: nowrap;
        }

        // .name {
        //     background-color: #ececec;
        // }
    }
}